import React from 'react';
import classNames from 'classnames';

import SEO from '../components/SEO';
import Hero from '../components/Hero';
import CopyBlock from '../components/CopyBlock';
import AlternatingImageText from '../components/AlternatingImageText';

const nsBase = 'page';
const ns = `${ nsBase }-about`;

const About = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	return (
		<div className={rootClassnames}>
			<SEO title={'About'} />
			<Hero
				modifier={'about'}
				heading={'We harness the power of light to make the world safe.'}
				image={{
					mainImageMobile: '/assets/images/about/About_Hero.jpg',
					altText: 'Family on bench',
				}}
			/>
			<CopyBlock
				backgroundColor={'#338FF5'}
				size={'large'}
				color={'white'}
				copy={'<h4><span style="color:#08347F">Violux exists to give you peace of mind.</span> We’re pioneering the world’s first smart UV-C light cleaning technology—empowering you to intimately know and trust the cleanliness of objects you frequently touch every day.</h4><br/><h4>Because when you know the items around you are bacteria-free, you can enjoy the peace of clean.</h4>'}
			/>
			<AlternatingImageText
				textHighlight={'Our team is growing.'}
				heading={'We’re looking for highly diverse, exceptionally talented and mission-driven people to join us.'}
				altText={'team shot'}
				mainImageMobile={'/assets/images/about/about1.png'}
				dataMedia
				imgOnLeft
				cta={{
					link: 'mailto:hello@violux.com',
					label: 'hello@violux.com',
				}}
			/>
			<AlternatingImageText
				textHighlight={'Our story is unique.'}
				heading={'If you’re interested in telling the world about our industry defining UV-C light cleaning technology, Let’s talk.'}
				altText={'Luma image'}
				mainImageMobile={'/assets/images/about/about2.png'}
				dataMedia
				cta={{
					link: 'mailto:hello@violux.com',
					label: 'hello@violux.com',
				}}
				last
			/>
		</div>
	);
};

export default About;
