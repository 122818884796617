import React from 'react';
import classNames from 'classnames';

import Image from '../Image';
import Link from '../Link';
import RenderStaticHTML from '../RenderStaticHTML';
import Reveal from '../Reveal';

const nsBase = 'component';
const ns = `${ nsBase }-copy-block`;

const CopyBlock = props => {
	const {
		centered,
		backgroundColor,
		color,
		size,
		image,
		copy,
		cta,
	} = props;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[`${ ns }--centered`]: centered,
		[`${ ns }--${ color }`]: color,
		[`${ ns }--${ size }`]: size,
	});

	return (
		<div className={rootClassnames} style={{backgroundColor}}>
			<div className={'container-fluid'}>
				<Reveal>
					{image && (
						<div className={`${ ns }__image`}>
							<Image {...image} />
						</div>
					)}
					{copy && (
						<div className={`${ ns }__copy`}>
							<RenderStaticHTML html={copy} />
						</div>
					)}
					{(cta && cta.label && cta.link) && (
						<div className={`${ ns }__cta`}>
							<Link to={cta.link}>
								{cta.label}
							</Link>
						</div>
					)}
				</Reveal>
			</div>
		</div>
	);
};

export default CopyBlock;
